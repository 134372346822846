import { Component } from "@angular/core";
import * as AWS from "aws-sdk";

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.css"],
})
export class ChatComponent {
  lexRuntime: AWS.LexRuntimeV2 | undefined;
  userMessage: string = "";
  lexResponse: string = "";

  constructor() {
    const botId = "LCBO41WGK9"; // Replaced by CircleCI
    const aliasId = "TSTALIASID"; // Replaced by CircleCI
    const region = ""; // Replaced by CircleCI

    AWS.config.update({ region });

    this.lexRuntime = new AWS.LexRuntimeV2();
  }

  sendMessage() {
    if (this.lexRuntime && this.userMessage.trim()) {
      const params = {
        botId: "LCBO41WGK9", // Replaced by CircleCI
        botAliasId: "TSTALIASID", // Replaced by CircleCI
        localeId: "en_US", // Update based on your Lex bot's locale
        sessionId: "user", // A unique identifier for the session
        text: this.userMessage,
      };

      this.lexRuntime.recognizeText(params, (err, data) => {
        if (err) {
          console.error("Error:", err);
          this.lexResponse = "Error communicating with Lex bot.";
        } else if (data && data.messages) {
          this.lexResponse = data.messages.map((msg) => msg.content).join(" ");
        }
      });
    }
  }
}

import { Injectable } from '@angular/core';
import {
  annuityColIds
} from '@product-marketplace/annuity-product/annuity-product-view/annuity-column-defs/column-defs-annuity-product';
import { Utilities } from '@common/utilities/utilities';
import { ANNUITY_PRODUCT_TYPES, } from '@product-marketplace/annuity-product/annuity-constants';
import {
  AnnuityIndicesModel
} from '@product-marketplace/annuity-product/annuity-product-view/annuity-models/annuity-indices.model';
import { TableColumn } from '@product-marketplace/annuity-product/annuity-helper.service';
import {
  EXCHANGE_SUMMARY_DETAILS,
  ExchangeScenario,
  MappedExchangeScenario
} from '@product-marketplace/annuity-product/annuity-product-view/exchange-1035/annuity-exchange.models';
import { CompanyLogoService } from '@creation-hub/services/company-logo.service';
import { ArithmeticUtilities } from '@common/utilities/arithmetic-utilities';
import { AnnuityUtils } from '@product-marketplace/annuity-product/annuity.utils';
import { TimeFormattingUtilities } from '@common/utilities/time-formatting-utilities';
import { AnnuityUserInformation } from '@common/resolvers/annuity-user-info.resolver';
import {
  AnnuitantDetails
} from '@product-marketplace/annuity-product/annuity-product-view/income-comparison/income-comparison/income-comparison.component';
import { AnnuityStateService } from '../annuity-state.service';
import {
  fileAttachment
} from '@product-marketplace/annuity-product/annuity-product-view/annuity-models/annuity-user-input.model';

export function formatCurrency(value) {
  const val = value || 0;
  return Utilities.formatCurrency(val, 0);
}

export class FixedRateProduct {
  accountType: string = null;
  baseRate: number = null;
  buyBtnPermission: boolean = null;
  carrier: string = null;
  cdscSchedule: string = null;
  currency: string = null;
  cusip: string = null;
  effectiveDate: string = null;
  firstYearYield: number = null;
  fullName: string = null;
  gmir: number = null;
  guaranteePeriod: string = null;
  guaranteePeriodYield: number = null;
  hasInterestBonus: boolean = null;
  hasPremiumBonus: boolean = null;
  interestBonus: number = null;
  maxAge: number = null;
  minAge: number = null;
  minimumPremium: number = null;
  mva: boolean = null;
  oesVendorName: string = null;
  oesVendorParam: string = null;
  premiumBand: number = null;
  premiumBandEnd: number = null;
  premiumBandStart: number = null;
  fundType: string = null;
  premiumBonus: number = null;
  rop: boolean = null;
  shortName: string = null;
  state: string = null;
  status: string = null;
  surrenderPeriod: string = null;
  surrenderPeriodYield: number = null;
  surrenderYears: number = null;
  type: string = null;
  vendorUniqueId: string = null;
}

export class AnnuityRate {
  bonus: string;
  buffer: string;
  capRate: string;
  creditingStrategy: string;
  dbRiders: string[];
  downsideParRate: number;
  effectiveDate: string;
  fixedRate: string;
  floor: string;
  formattedTermPeriod: string;
  guaranteePeriod;
  guaranteeTerm;
  indexCode: string;
  lbRiders: string[];
  misfitRates: MisfitRate[];
  mva: boolean; // todo
  parRate: string;
  performanceTrigger: string;
  performanceTriggerCredit: string;
  premiumBandEnd: number;
  premiumBandStart: number;
  rateKey: string;
  riders: string[];
  rop: boolean; // todo
  spread: string;
  strategyFee: number;
  term: string;
  underlieIndex: string;
  name: string;
  ibRiderRule: string;
  dbRiderRule: string;
  allowConfig: boolean;
}

export class MisfitRate extends AnnuityRate {
  // Allocated Misfits
  weight: number;
  // Layered Misfits
  periodRangeMin: number;
  periodRangeMax: number;
  // Threshold Misfits
  percentRangeMin: number;
  percentRangeMax: number;
}

export type MisFitStrategy = 'Allocated' | 'Layered' | 'Threshold' | 'Better Of';

export interface VaRate {
  id: string;
  name: string;
  subAccounts: VaSubAccounts[];
}

export interface VaSubAccounts {
  id: string;
  fee: number;
  fiveYearReturn: number | string;
  name: string;
  oneYearReturn: number | string;
  tenYearReturn: number | string;
  threeYearReturn: number | string;
  ytdReturn: number | string;
  netExpenseRatio: number | string;
  bucket: string;
  buckets?: Buckets[];
}

export interface Buckets {
  bucketName: string;
  exposureRate: number;
}

export class AnnuityRider {
  calcMethod: string;
  deferralCredit: string;
  deferralTerm: string;
  effectiveDate: Date;
  effectiveEnd: Date;
  incomeRefMaxAge: number; // years?
  incomeRefMinAge: number; // years?
  incomeStartMaxAge: number;
  incomeStartMinAge: number;
  issueMaxAge: number;
  issueMinAge: number;
  required: boolean;
  riderId: string;
  riderJointCost: number;
  riderName: string;
  riderSingleCost: number;
  riderType: string;
  uvi: string;
}

export class DeathBenefitRider {
  accumulationRate: number;
  accumulationType: string;
  dbRiderId: string;
  dbRiderName: string;
  dbRiderType: string;
  issueMaxAge: number;
  issueMinAge: number;
  required: boolean;
  riderFee: number;
}

export class CarrierInfo {
  parameter: string;
  type: string;
}

export interface AnnuityProductRate {
  allowConfig: boolean;
  bailout: number;
  bonus: number;
  buffer: number;
  capRate: number;
  creditingStrategy: string;
  currency: string;
  effectiveDate: string;
  guaranteeTerm: string;
  indexCode: string;
  interestRate;
  misfitRates: any[];
  parRate: number;
  performanceTrigger: number;
  premiumBandEnd: number;
  premiumBandStart: number;
  rateKey: string;
  term: string;
  underlieIndex: string;
}

export class GeneralAnnuityProduct {

  // fields passed from income comparison that have annuitant details already filled out for configure flows
  annuitantDetails: AnnuitantDetails;

  accountType: string;
  addOnPremium: number;
  adminFee: number;
  annuityRates: AnnuityRate[] = [];
  annuityRiders: AnnuityRider[] = [];
  backTested?: boolean;
  buyBtnPermission: boolean;
  carrier: string;
  carrierInfo: CarrierInfo[];
  carrierLogo: string;
  clientNeed = 'Growth';
  configureBtnPermission: boolean;
  cusip: string;
  db: string;
  dbRiders: DeathBenefitRider[] = [];
  // If true - We do not have analytical data available for product
  disConf?: boolean;
  document: string;
  eeb: string;
  filteredAnnuityRiders: AnnuityRider[] = [];
  filteredDbRiders: DeathBenefitRider[] = [];
  financialRating: string;
  fixedRateProducts: FixedRateProduct[] = [];
  formattedMefee: string;
  formattedProductFee: string;
  frwd: number;
  formattedFrwd: string;
  hasFrwdText: boolean;
  glwb = false;
  gmwb = false;
  hasInterestBonus: boolean;
  hasPremiumBonus: boolean;
  incomeRider: string;
  dbRider: string;
  bonus: string;
  id?: string;
  indices: string;
  maxAge: number;
  maxAtznAge: number;
  maxPremium: number;
  mefee: number;
  productFee: number;
  minAge: number;
  minPremium: number;
  mva: boolean;
  oesVendorName: string;
  oesVendorParam: string;
  prodConfigName?: string;
  productName: string;
  productRates?: AnnuityProductRate[];
  productType: string;
  rateBanding: string;
  rop: boolean;
  state?: string;
  activeState?: string;
  subAccounts: string;
  surrenderCharge: string;
  surrenderPeriod: string;
  surrenderYears: number;
  vaRates: VaRate[] = [];
  vendorUniqueId: string;
  productProfile: string;
  waivers: string;
  amBest: string;
  fitch: string;
  moodys: string;
  sAndP: string;
  isYellowFlagged: boolean;
  fileBodyRequest?: any;
  oesDocumentUploadType?: 'PRODUCT RATES' | 'INCOME COMPARISON' | 'PRODUCT COMPARISON';
  fileAttachments?: fileAttachment[];
}

export class AnnuityResponse {
  annuityProducts: GeneralAnnuityProduct[];
  userAnnuityPermissions: string[];
}

export const RateRiderPropertiesExcludedFromPercent = [
  annuityColIds.RT_TERM,
  annuityColIds.RT_CUSTOM_FORMATTED_PERIOD,
  annuityColIds.RT_STRATEGY,
  annuityColIds.RT_INDEX,
  annuityColIds.RT_RATE_KEY,
  annuityColIds.RT_INDEX_CODE,
  annuityColIds.RT_PREMIUM_BAND_START,

  annuityColIds.RD_RIDER_NAME,
  annuityColIds.RD_RIDER_TYPE,
  annuityColIds.RD_DEFER_TERM,
  annuityColIds.RD_CALC_METHOD,
  annuityColIds.RD_EFF_DATE,
  annuityColIds.RD_EFF_END,
  annuityColIds.RD_MIN_AGE,
  annuityColIds.RD_MAX_AGE,
  annuityColIds.RD_MIN_REF_AGE,
  annuityColIds.RD_MAX_REF_AGE,
  annuityColIds.RD_RIDER_ID,
  'issueMinAge', // not exposed to chart
  'issueMaxAge', // not exposed to chart
];

export const IncomeOptions = [
  annuityColIds.GLWB,
  annuityColIds.GMWB,
];

// BE Properties // mapped Properties
export const BE_PROPERTIES = {
  FULL_NAME: 'fullName', // productName
  CDSC_SCHEDULE: 'cdscSchedule',  // surrenderCharge
  MVA: 'mva', // mva
  ROP: 'rop', // rop
  STATE: 'state', // state
  CUSIP: 'cusip' // cusip
};

const annuityMap = new Map<string, string>([
  ['Corebridge Financial', 'corebridge.png'],
  ['Allianz', 'allianz.png'],
  ['American Equity', 'american-equity.png'],
  ['American National', 'american-national.png'],
  ['Ameritas', 'ameritas.png'],
  ['Athene', 'athene.png'],
  ['Brighthouse', 'brighthouse-financial.png'],
  ['CUNA Mutual Group', 'cuna-mutual-group.png'],
  ['Delaware Life', 'delaware-life.png'],
  ['Eagle Life', 'eagle-life.png'],
  ['Equitable', 'equitable.png'],
  ['F&G', 'FG_Logo.png'],
  ['Fidelity Investments', ''],
  ['Global Atlantic', 'global-atlantic-financial-group.png'],
  ['Great American Life', 'great-american-life.png'],
  ['Guardian', ''],
  ['Integrity Life (W&S)', 'integrity-life-insurance-company.png'],
  ['Jackson National', 'jackson-national.png'],
  ['Jefferson National', 'nationwide.png'],
  ['Lincoln Financial Group', 'lincoln-financial-group.png'],
  ['Luma Carrier', ''],
  ['MassMutual Ascend', 'MassMutualAscend.jpg'],
  ['Mass Mutual Strategic Distributors', 'mass-mutual.png'],
  ['Midland National', 'midland-national.png'],
  ['Nationwide', 'nationwide.png'],
  ['New York Life', 'new-york-life.png'],
  ['Pacific Life', 'pacific-life.png'],
  ['Principal Financial Group', ''],
  ['Protective', 'protective.png'],
  ['Prudential (US)', 'prudential.png'],
  ['Reliance Standard', 'reliance-standard.png'],
  ['Sammons', 'sammons-financial.png'],
  ['Securian Financial', 'securian-financial.png'],
  ['Securian', ''],
  ['Security Benefit', 'sb_logo.jpeg'],
  ['Symetra Life', 'symetra-life.png'],
  ['The Standard', 'the-standard.png'],
  ['Transamerica', 'transamerica.png'],
  ['VALIC', 'corebridge.png'],
  ['Western-Southern Life', 'western-southern.png'],
  ['Atlantic Coast Life', 'atlantic-coast-life.png'],
  ['EquiTrust', 'equitrust.png'],
  ['Guggenheim', 'guggenheim.png'],
  ['North American', 'north-american.png'],
  ['Oceanview', 'oceanview.png'],
  ['Sagicor', 'sagicor-life.png'],
  ['Sentinel Security', 'sentinal.png'],
  ['SILAC', 'silac.jpeg'],
  ['National Western', 'national-western.png'],
  ['United Life', 'united-life.png'],
  ['Prosperity', 'prosperity.png'],
  ['Clear Spring Life and Annuity Company', 'clear_spring.png'],
  ['Americo Financial', 'americo-financial.png'],
  ['Aspida', 'aspida.png'],
  ['Farmers Life', 'farmers-life.png'],
  ['GBU Life', 'gbu-life.png'],
  ['GILICO', 'gilico.png'],
  ['Mutual of Omaha', 'mutual-of-omaha.png'],
  ['Nassau', 'nassau.png'],
  ['National Life Group', 'national-life.png'],
  ['Ohio State Life', 'ohio-state-life.png'],
  ['Pacific Guardian', 'pacific-guardian.png']
]);
export type FilterContext = {
  filterName: string;
  filterText: string
};

export class RateViewExport {
  filters: FilterContext[] = [];
  sortParameters: FilterContext[] = [];
  rateRow: { rate: AnnuityRate, position: number }[] = [];
}

export interface ProductProfile {
  index: number;
  carrier: string;
  productName: string;
  rider: string;
  premium: string;
  accountValue: string;
  startingIncome: string;
  totalIncome: string;
  amBest: string;
  fitch: string;
  moodys: string;
  standardAndPoor: string;
  accountType: string;
  cdsc: string;
  freeWithdrawal: string;
  minimumPremium: string;
  maximumPremium: string;
  addOnPremium: string;
}

export interface AnnuityComparePdfRequest {
  filters: FilterContext[];
  lineCharts: {
    growthSvg: string;
    incomeSvg?: string;
  };
  productProfiles: ProductProfile[];

}

@Injectable({
  providedIn: 'root'
})
export class AnnuityMapperService {

  constructor(private annuityStateService: AnnuityStateService) {
  }

  mapObjWithSameProperties = (source, ...destinations) => {
    return destinations.forEach(dst => Object.keys(dst)
      .filter(key => source.hasOwnProperty(key))
      .reduce((srcObj, key) => {
        srcObj[key] = dst[key];
        return srcObj;
      }, source));
  }

  mapAnnuityResponse(annuityResponse: any, annuityUserInformation) {
    const mappedAnnuityResponse = new AnnuityResponse();
    mappedAnnuityResponse.annuityProducts = this.mapGeneralProducts(annuityResponse, annuityUserInformation);
    mappedAnnuityResponse.userAnnuityPermissions = annuityResponse.productType || [];
    if (annuityResponse.buyButtonMsg) {
      this.annuityStateService.annuityBrokerDealerConfig.buyButtonMsg = annuityResponse.buyButtonMsg;
    }
    return mappedAnnuityResponse;
  }

  mapGeneralProducts(annuityResponse: any, annuityUserInformation: AnnuityUserInformation): GeneralAnnuityProduct[] {
    // property name from BE // mapped property
    const UNIQUE_PROPERTIES = {
      FIXED_RATE: [
        // BE_KEYS.FULL_NAME, // <- this is used as the key, won't need to be checked
        BE_PROPERTIES.CDSC_SCHEDULE,
        BE_PROPERTIES.MVA,
        BE_PROPERTIES.ROP,
        BE_PROPERTIES.STATE,
        BE_PROPERTIES.CUSIP,
      ],
      FIA_RILA: [
        // BE_KEYS.FULL_NAME, // <- this is used as the key, won't need to be checked
        BE_PROPERTIES.CDSC_SCHEDULE,
        BE_PROPERTIES.MVA,
        BE_PROPERTIES.ROP,
        BE_PROPERTIES.CUSIP,
      ]
    };

    const filteredProducts = new Map<string, any>();
    const annuityProducts = annuityResponse?.generalProduct?.filter(annuityProduct => {

      if (annuityProduct.type === ANNUITY_PRODUCT_TYPES.DIA_SPIA) {
        return false;
      }

      let uniqueKeys: string[];
      switch (annuityProduct.type) {
        case ANNUITY_PRODUCT_TYPES.FIXED_RATE:
          uniqueKeys = UNIQUE_PROPERTIES.FIXED_RATE;
          break;
        case ANNUITY_PRODUCT_TYPES.FIA: // fall through
        case ANNUITY_PRODUCT_TYPES.RILA: // fall through
          uniqueKeys = UNIQUE_PROPERTIES.FIA_RILA;
          break;
        default:
          return true;
      }

      const annuityProductName = annuityProduct.fullName;
      annuityProduct.trainingWarnMsg = annuityResponse.trainingWarnMsg;

      if (filteredProducts.has(annuityProductName)) {
        const annuityProductMap = filteredProducts.get(annuityProductName);

        for (const product of annuityProductMap) {
          if (uniqueKeys.every(key => product[key] === annuityProduct[key])) {
            return false;
          }
        }

        annuityProductMap.push(annuityProduct);
      } else {
        filteredProducts.set(annuityProductName, [annuityProduct]);
      }
      return true;
    }).map(annuityProduct => this.mapSingleProduct(annuityProduct, annuityUserInformation));

    return annuityProducts;
  }

  addFinancialRatingProperties(annuityProduct) {
    if (!annuityProduct) {
      return;
    }
    // example of a financial rating string being parsed:
    // financialRating: "A(A.M. Best)|A+(Fitch)|A2(Moody's)|A+(Standard & Poor's)"
    annuityProduct.moodys = '';
    annuityProduct.sAndP = '';
    annuityProduct.amBest = '';
    annuityProduct.fitch = '';
    const splitRatings = annuityProduct.financialRating?.split('|');
    if (!splitRatings || splitRatings.length < 1) {
      return;
    }
    for (const rating of splitRatings) {
      if (rating.toUpperCase().includes('(A.M. Best)'.toUpperCase())) {
        annuityProduct.amBest = rating.substring(0, rating.indexOf('('));
      }
      if (rating.toUpperCase().includes('(Moody\'s)'.toUpperCase())) {
        annuityProduct.moodys = rating.substring(0, rating.indexOf('('));
      }
      if (rating.toUpperCase().includes('(Standard & Poor\'s)'.toUpperCase())) {
        annuityProduct.sAndP = rating.substring(0, rating.indexOf('('));
      }
      if (rating.toUpperCase().includes('(Fitch)'.toUpperCase())) {
        annuityProduct.fitch = rating.substring(0, rating.indexOf('('));
      }
    }
  }

  mapSingleProduct(annuityProduct: any, annuityUserInformation: AnnuityUserInformation) {
    this.addFinancialRatingProperties(annuityProduct);
    const isProductTypeFIA =
      annuityProduct.type.toUpperCase() === ANNUITY_PRODUCT_TYPES.FIA;
    const isProductTypeRILA =
      annuityProduct.type.toUpperCase() === ANNUITY_PRODUCT_TYPES.RILA;
    const {annuityBtnPermissions, oes} = annuityUserInformation;
    const mappedAnnuityProduct = new GeneralAnnuityProduct();
    const carrierLogo = this.getAnnuityLogo(annuityProduct.carrier);

    Object.assign(mappedAnnuityProduct, annuityProduct);

    mappedAnnuityProduct.document = annuityProduct.productCode;
    mappedAnnuityProduct.productType = annuityProduct.type;
    mappedAnnuityProduct.productName = annuityProduct.fullName;
    mappedAnnuityProduct.surrenderCharge = annuityProduct.cdscSchedule;
    mappedAnnuityProduct.minPremium = annuityProduct.minimumPremium;
    mappedAnnuityProduct.maxPremium = annuityProduct.maximumPremium;
    mappedAnnuityProduct.formattedMefee = isProductTypeFIA || isProductTypeRILA
      ? this.formatNaForMeAndProductFee(annuityProduct.mefee)
      : this.formatPercentage(annuityProduct.mefee);
    mappedAnnuityProduct.formattedProductFee = isProductTypeFIA || isProductTypeRILA
      ? this.formatNaForMeAndProductFee(annuityProduct.productFee)
      : this.formatPercentage(annuityProduct.productFee);
    mappedAnnuityProduct.indices = annuityProduct?.underlieIndices?.join();
    mappedAnnuityProduct.configureBtnPermission =
      annuityBtnPermissions.configureBtnPermission;
    mappedAnnuityProduct.buyBtnPermission =
      annuityBtnPermissions.buyBtnPermission;
    mappedAnnuityProduct.oesVendorName = oes?.vendorName; // TODO
    mappedAnnuityProduct.oesVendorParam = oes?.parameter;
    mappedAnnuityProduct.carrierLogo = carrierLogo;

    if (mappedAnnuityProduct.frwd != null && !isNaN(mappedAnnuityProduct.frwd)) {
      mappedAnnuityProduct.frwd = +ArithmeticUtilities.divide(mappedAnnuityProduct.frwd, 100);
      mappedAnnuityProduct.formattedFrwd = ArithmeticUtilities.multiply(mappedAnnuityProduct.frwd, 100) + '%';
    }

    if (annuityProduct.productRiders) {
      annuityProduct.productRiders.forEach((rider) => {
        const riderType = rider.riderType.toLowerCase();

        if (mappedAnnuityProduct.hasOwnProperty(riderType)) {
          mappedAnnuityProduct[riderType] = true;
        }

        if (IncomeOptions.includes(riderType)) {
          mappedAnnuityProduct.clientNeed = 'Income';
        }
      });
    }

    const someRatesHaveIbView = annuityProduct.productRates?.some(productRate => productRate.riders?.length > 0);
    const ibRiderLength = annuityProduct.productRiders?.length ?? 0;
    const someRatesHaveDbView = annuityProduct.productRates?.some(productRate => productRate.dbRiders?.length > 0);
    const dbRiderLength = annuityProduct.dbRiders?.length ?? 0;
    mappedAnnuityProduct.annuityRates = annuityProduct.productRates?.map(
      (productRate) => {
        const mappedAnnuityRate = new AnnuityRate();
        Object.assign(mappedAnnuityRate, productRate);

        // TODO - once formatting no longer occurs here - clean up configure component
        mappedAnnuityRate.floor = isProductTypeFIA ?
          this.formatNA(mappedAnnuityRate.floor) : this.formatPercentageIncludingZero(productRate.floor);
        mappedAnnuityRate.buffer = isProductTypeFIA ?
          this.formatNA(mappedAnnuityRate.buffer) : this.formatPercentageIncludingZero(productRate.buffer);

        if (isProductTypeFIA || isProductTypeRILA) {
          mappedAnnuityRate.formattedTermPeriod = this.formatTermPeriod(mappedAnnuityRate, !!mappedAnnuityRate.term);
        }

        if (ibRiderLength > 0 && someRatesHaveIbView) {
          const associatedIncomeRiders = mappedAnnuityRate.riders?.map((rider: string) => {
            return annuityProduct.productRiders?.filter((incomeRider) => incomeRider.riderId === rider)
              .map(incomeRider => incomeRider.riderName);
          }).join(', ');

          if (associatedIncomeRiders) { // scenario 01
            mappedAnnuityRate.ibRiderRule = associatedIncomeRiders;
          } else if (!associatedIncomeRiders && someRatesHaveIbView) {
            mappedAnnuityRate.ibRiderRule = 'No Rider'; // scenario 03
          }
        } else if ((ibRiderLength > 0 && !someRatesHaveIbView)) { // scenario 02
          mappedAnnuityRate.ibRiderRule = 'All Available';
        } else { // scenario 04
          mappedAnnuityRate.ibRiderRule = 'None Available';
        }

        if (dbRiderLength > 0 && someRatesHaveDbView) {
          const associatedDeathRiders = mappedAnnuityRate.dbRiders?.map((rider: string) => {
            return annuityProduct.dbRiders?.filter((dbRider) => dbRider.dbRiderId === rider)
              .map(productRider => productRider.riderName);
          }).join(', ');

          if (associatedDeathRiders) { // scenario 01
            mappedAnnuityRate.dbRiderRule = associatedDeathRiders;
          } else if (!associatedDeathRiders && someRatesHaveDbView) {
            mappedAnnuityRate.dbRiderRule = 'No Rider'; // scenario 03
          }
        } else if ((dbRiderLength > 0 && !someRatesHaveDbView)) { // scenario 02
          mappedAnnuityRate.dbRiderRule = 'All Available';
        } else { // scenario 04
          mappedAnnuityRate.dbRiderRule = 'None Available';
        }

        if (productRate.name) {
          mappedAnnuityRate.creditingStrategy = `${productRate.creditingStrategy}/${productRate.name} `;
        }

        return mappedAnnuityRate;
      }
    );

    if (!AnnuityUtils.isShellProduct(mappedAnnuityProduct)) {
      mappedAnnuityProduct.incomeRider = annuityProduct.productRiders?.length > 0 ? 'Optional' : 'No';
    } else {
      mappedAnnuityProduct.incomeRider = '-';
    }

    mappedAnnuityProduct.annuityRiders = annuityProduct?.productRiders?.map((productRider) => {
        if (productRider.required) {
          mappedAnnuityProduct.incomeRider = 'Mandatory';
        }
        return this.formatRiderPercentages(productRider);
      }
    );

    mappedAnnuityProduct.dbRider = '-';
    if (!AnnuityUtils.isShellProduct(mappedAnnuityProduct) && annuityProduct?.dbRiders?.length > 0) {
      let anyEmptyTypes = false;
      for (const currentRider of annuityProduct?.dbRiders) {
        const riderType = currentRider.dbRiderType;

        if (!riderType) {
          anyEmptyTypes = true;
          mappedAnnuityProduct.dbRider = '-';
          continue;
        }

        if (riderType === 'O') {
          mappedAnnuityProduct.dbRider = 'Yes';
          break;
        } else if (riderType === 'S' && !anyEmptyTypes) {
          mappedAnnuityProduct.dbRider = 'No';
        }
      }
    }

    const currentProductRate = annuityProduct.productRates?.find((productRate) => productRate?.bonus);
    mappedAnnuityProduct.bonus = currentProductRate?.bonus || null;
    mappedAnnuityProduct.filteredAnnuityRiders = [...(mappedAnnuityProduct.annuityRiders ?? [])];
    mappedAnnuityProduct.filteredDbRiders = [...(mappedAnnuityProduct.dbRiders ?? [])];

    mappedAnnuityProduct.fixedRateProducts =
      annuityProduct.fixedProductRates?.map((fixedProductRate) => {
        const mappedFixedRateProduct = new FixedRateProduct();
        this.mapObjWithSameProperties(
          mappedFixedRateProduct,
          annuityProduct,
          fixedProductRate
        );
        mappedFixedRateProduct.buyBtnPermission =
          annuityBtnPermissions.buyBtnPermission;
        mappedFixedRateProduct.oesVendorName = oes?.vendorName;
        mappedFixedRateProduct.oesVendorParam = oes?.parameter;
        return mappedFixedRateProduct;
      });

    return mappedAnnuityProduct;
  }


  mapRateFieldsForTable(annuityRate: AnnuityRate, columnKeys: string[]): AnnuityRate {
    const formattedRate = new AnnuityRate();
    Object.assign(formattedRate, annuityRate);
    columnKeys.forEach(columnKey => {
      if (annuityRate.hasOwnProperty(columnKey)) {
        formattedRate[columnKey] = this.formatPercentageIncludingZero(annuityRate[columnKey]);
      }
    });

    // todo: the formatTermPeriod is passing 'includeTerm' as true, but term may not be on the rate.
    //  I passed in a boolean to determine if term should be included, but that may not be the best way to do this.
    if (annuityRate.guaranteePeriod) {
      formattedRate.formattedTermPeriod = this.formatTermPeriod(annuityRate, annuityRate?.term != null);
    }

    return formattedRate;
  }

  // TODO - refactor - we're post demo
  formatRiderPercentages(productRider): AnnuityRider {
    const mappedProductRider = new AnnuityRider();
    Object.assign(mappedProductRider, productRider);

    mappedProductRider.effectiveDate = productRider.effectivedate ? new Date(productRider.effectivedate) : null;
    mappedProductRider.effectiveEnd = productRider.effectiveend ? new Date(productRider.effectiveend) : null;

    Object.keys(mappedProductRider).forEach((key) => {
      if (mappedProductRider.hasOwnProperty(key) && !RateRiderPropertiesExcludedFromPercent.includes(key)) {
        const property = mappedProductRider[key];

        if (!property && key === annuityColIds.RD_COST_SINGLE || key === annuityColIds.RD_COST_JOINT) {
          // TODO - REMOVE POST DEMOS
          mappedProductRider[key] = '0%';
        } else if (key === 'uvi') {
          mappedProductRider[key] = property;
        } else {
          mappedProductRider[key] = this.formatPercentage(property);
        }
      }
    });

    return mappedProductRider;
  }

  formatProductRateToFrontierStrategy(allocatedProductRates, frontierStrategies) {
    const keysWithFormattedAllocation = [];
    const idsWithFormattedAllocation = [];
    let totalAllocation = 0;

    for (const [strategyKey, strategyVal] of frontierStrategies.entries()) {
      const match = allocatedProductRates.filter((rate) => {
        return (rate.data.rateKey ? rate.data.rateKey : rate.data.id) === strategyVal.strategyId;
      });
      if (match.length > 0) {
        const allocation = match.reduce((accumulator, current) => accumulator + current.allocation, 0);
        const formattedAllocation = `${(allocation / 100)}`;

        const formattedKey = `${strategyKey}:${formattedAllocation}`;
        const formattedId = `${strategyVal.strategyId},${formattedAllocation}`;

        keysWithFormattedAllocation.push(formattedKey);
        idsWithFormattedAllocation.push(formattedId);

        totalAllocation += allocation;
      }
    }

    return totalAllocation === 100 ? {
      keysWithFormattedAllocation,
      idsWithFormattedAllocation
    } : {keysWithFormattedAllocation: [], idsWithFormattedAllocation: []};
  }

  formatNA(val) {
    if (!val) {
      return 'N/A';
    }
    return val;
  }

  formatNaForMeAndProductFee(val): string {
    if (!val) {
      return 'N/A';
    }
    return val.toFixed(2).concat('%');
  }

  private formatPercentage(rawNum: number | string) {
    if (typeof rawNum === 'string') {
      if (rawNum.includes('%')) {
        return rawNum;
      }

      rawNum = parseFloat(rawNum);
    }

    if (!rawNum) {
      return '-';
    }

    return Utilities.formatPercentageFromDecimal(rawNum);
  }

  private formatPercentageIncludingZero(rawNum: number | string) {
    if (typeof rawNum === 'string') {
      if (rawNum.includes('%')) {
        return rawNum;
      }

      rawNum = parseFloat(rawNum);
    }

    if (typeof rawNum !== 'number' || isNaN(rawNum)) {
      return '-';
    }

    return Utilities.formatPercentageFromDecimal(rawNum);
  }

  formatFrontierData(efficientFrontierData, productName) {
    return efficientFrontierData.efficientFrontierDots?.map(obj => this.formatEfficientFrontierTooltip(obj, productName));
  }

  filterFrontierData(efficientFrontierData = [], strategies = new Map<any, any>(), currentIndexes) {
    const allowedStrategies = Array.from(strategies)
      .filter(([key, val]) => currentIndexes.includes(val?.strategyId))
      .map(([key]) => key);

    return efficientFrontierData?.filter((currentObj: any) => {
      const allocatedStrategies = currentObj.saKey?.split(',').map(subStr => subStr.split(':')[0]);
      return allocatedStrategies.every(allocatedStrategy => allowedStrategies.includes(allocatedStrategy));
    }) || [];
  }

  formatEfficientFrontierTooltip(efficientFrontierDot, productName) {
    const bottomRows = efficientFrontierDot.displayVal?.map(displayVal => {
      const strategyRow = displayVal?.join(', ');
      return '<div style="font-weight: 500">' + strategyRow + '</div>';
    }).join('');

    const ret = `Return: ${(efficientFrontierDot.y * 100).toFixed(3)}%`;
    const vol = `Volatility: ${(efficientFrontierDot.x * 100).toFixed(3)}%`;
    const topPart = `<div style="color: #333538; font-style: oblique; margin-bottom: 4px;">${productName}</div><div style="font-weight: 500;">${ret} | ${vol}</div>`;
    const hr = '<hr style="margin: 8px 0; border: none;height: 1px; background-color: #cdcdcd;">';

    const formattedTable = topPart + hr + bottomRows;

    return {
      ...efficientFrontierDot,
      formattedTable
    };
  }

  mapAnnuityIndices(annuityIndices: AnnuityIndicesModel[]): AnnuityIndicesModel[] {
    return annuityIndices.map(annuityIndex => {
      const annuityIndexModel = Object.assign(new AnnuityIndicesModel(), annuityIndex);
      annuityIndexModel.convertToDateObjects();
      return annuityIndexModel;
    });
  }

  private formatTermPeriod = (annuityRate: AnnuityRate, includeTerm?: boolean) => {
    const regex = new RegExp(/^((\d*)\s[Years]+)\s((\d*)\s[Months]+)$/gi);
    let period = annuityRate.guaranteePeriod || annuityRate.guaranteeTerm; // is one or the other depending on fia/rila or fixed
    const regexExec = regex.exec(period);
    if (regexExec?.length === 5) {
      const yearNumericValue = regexExec[2];
      const monthNumericValue = regexExec[4];

      if (+yearNumericValue === 1) {
        period = 'Annual';
      } else {
        period = `${yearNumericValue} year`;
      }

      if (+monthNumericValue > 0) {
        period = `${period} ${monthNumericValue} month`;
      }
    }

    return includeTerm ? `${annuityRate.term} / ${period}` : period;
  }

  formatAnnuityRateFromColumn(annuityRate: AnnuityRate, columns: Array<TableColumn>): string {
    const excludeDisplayName = [annuityColIds.RT_CUSTOM_FORMATTED_PERIOD, annuityColIds.RT_STRATEGY];
    return columns.reduce((formattedIndex: string, column: TableColumn) => {
      const value = annuityRate[column.colKey];
      if (value && value !== '-') {
        const formattedValue = excludeDisplayName.includes(column.colKey)
          ? value : `${value} ${column.colDisplayName}`;
        return formattedIndex + (formattedIndex ? ` / ${formattedValue}` : formattedValue);
      }
      return formattedIndex;
    }, '');
  }

  mapExchangeData(exchangeScenarios: ExchangeScenario[], exchangeInformation): MappedExchangeScenario {
    const exchangeSummaryMap = new Map<string, any>(EXCHANGE_SUMMARY_DETAILS.map(e => [e.id, e]));

    const mappedExchangeScenarios = exchangeScenarios.sort((a, b) => {
      return exchangeSummaryMap.get(a.scenario)?.position - exchangeSummaryMap.get(b.scenario)?.position;
    }).map((exchangeScenario) => {
      return {
        exchangeScenario,
        exchangeDetails: {
          text: exchangeSummaryMap.get(exchangeScenario?.scenario)?.name ?? exchangeScenario?.scenario,
          value: exchangeScenario?.exchangeInSummary?.totalEv - exchangeScenario?.exchangeOutSummary?.totalEv,
          exchangeInSummary: exchangeScenario?.exchangeInSummary,
          exchangeOutSummary: exchangeScenario?.exchangeOutSummary,
          position: exchangeSummaryMap.get(exchangeScenario?.scenario).position,
          carrierIn: exchangeInformation.carrierIn || 'Carrier IN <PLACEHOLDER>',
          carrierOut: exchangeInformation.carrierOut || 'Carrier Out <PLACEHOLDER>',
        }
      };
    });

    return {
      exchangeScenarios: mappedExchangeScenarios,
      exchangeBenefits: mappedExchangeScenarios.reduce((exchangeBenefit: any, mappedScenario) => {
        const exchangeScenario = mappedScenario.exchangeScenario;
        exchangeBenefit.incomeBenefits.productIn[exchangeScenario.scenario] = exchangeScenario.exchangeInSummary?.incomeEv;
        exchangeBenefit.incomeBenefits.productOut[exchangeScenario.scenario] = exchangeScenario.exchangeOutSummary?.incomeEv;
        exchangeBenefit.deathBenefits.productIn[exchangeScenario.scenario] = exchangeScenario.exchangeInSummary?.dbPaymentEv;
        exchangeBenefit.deathBenefits.productOut[exchangeScenario.scenario] = exchangeScenario.exchangeOutSummary?.dbPaymentEv;
        return exchangeBenefit;
      }, {
        deathBenefits: {
          productIn: {},
          productOut: {}
        },
        incomeBenefits: {
          productIn: {},
          productOut: {}
        }
      })
    };
  }

  getAnnuityLogo(carrierShortName) {
    const carrierImg = annuityMap.get(carrierShortName);
    if (!carrierImg) {
      console.error('No image for: ', carrierShortName);
    }
    return carrierImg ? `${CompanyLogoService.CMS_URL}annuities/${carrierImg}` : '';
  }

  // This is a quick fix for saved product configs mappings
  mapSavedProductConfig(annuityProduct) {
    annuityProduct.carrierLogo = this.getAnnuityLogo(annuityProduct.carrier);
    annuityProduct.productName = annuityProduct.productName ?? annuityProduct.prodName;
    return annuityProduct;
  }


  /**
   * This method is used to map the order event data to the order history table
   * @param d: grid data to be mutated (sorry no types!)
   *
   * Mutates the data (performance decision since it happens on an array of data, and avoiding mutation would require a deep copy)
   * Mutation should be safe in this context
   */
  mapOrderEvent(d: any) {
    const sortedEvents = (d.eventRows).sort((a, b) => {
      if (a?.transactionDate === b?.transactionDate) {
        return 0;
      }
      return a?.transactionDate > b?.transactionDate ? -1 : 1;
    });
    const mostRecentEvent = sortedEvents[0];
    if (mostRecentEvent) {
      mostRecentEvent.highlightRow = true;
      d.mostRecentStatus = mostRecentEvent.status;
      d.mostRecentTxn = mostRecentEvent.transactionDate;
      d.mostRecentTxnFmt = TimeFormattingUtilities.FormatDateAndTimeHasPassedSinceNow(mostRecentEvent.transactionDate);
    }
    d.createdDate = TimeFormattingUtilities.FormatDateAndTimeHasPassedSinceNow(d.eventRows[d.eventRows.length - 1].transactionDate);
    d.clientName = d.applicationName || (d.entityOwnership ? d.entityOwnership : `${d.clientFirstName} ${d.clientLastName}`);
  }

  mapAnnuityOrderEventData(orderEventData: any[]) {
    orderEventData.forEach(d => {
      this.mapOrderEvent(d);
    });
    return orderEventData;
  }
}

import { LumaColor } from "./utils/luma-colors";

export type ConfigurableDashboard = {
  enableConfigurableDashboard: boolean;
  configurableDashboardDefaultDesk: boolean;
  headerNameOverride: string;
  isLivePortfolioSummaryAvailable: boolean;
  isLivePortfolioDetailAvailable: boolean;
  isITMNotionalAvailable: boolean;
  isUpcomingEventBreakdownStackedAvailable: boolean;
  isUpcomingEventBreakdownNonStackedAvailable: boolean;
  isExposureBreakdownAvailable: boolean;
  isOverviewSummaryDetailsAvailable: boolean;
  isMyHoldingsAvailable: boolean;
  isRemainingTimeToMaturityAvailable: boolean;
  isProjectedCashFlowTimelineHeatmapAvailable: boolean;
  isProjectedCashFlowAvailable: boolean;
  isPendingPricingRequestsIssuerAvailable: boolean;
  isPendingPricingRequestsFAAvailable: boolean;
  isCreationTemplatesAvailable: boolean;
  isApplicationTrackerAvailable: boolean;
  isProductMarketplaceOfferingsAvailable: boolean;
  isContactsAvailable: boolean;
};

export type DisplayDeskConfig = {
  deskId?: number;
  displayExternalStructureName: boolean;
  enableAnnuityLM2: boolean;
  enableAnnuityLnACheck: boolean;
  displayBloombergTicker: boolean;
  displayProductSymbol: boolean;
  dailySummaryOccurrence: string;
  displayLegacyDashboard: boolean | null;
  displayLegacyNavigation: boolean | null;
  displayIssuerNameWithLogo: boolean | null;
  marketDataSourcePreference: any;
  hideDashboardWidgets: boolean;
  hideAllDisclaimer: boolean;
  hideAnnuityBuyButton: boolean;
  allowCustomizeAnnuityAppName: boolean;
  allowANOESDocumentUpload: boolean;
  allowAnnuityBuyWOTraining: boolean;
  hideProductMarketplaceMenu: boolean;
  hidePoweredByLuma: boolean;
  hideHomeMenu: boolean;
  displayMarkToMarketSource: boolean;
  enableDashboardInsights: boolean;
  displayLegacyLifecycleManager: boolean;
  id: string;
  canViewLikelihoodOfCall: boolean;
  hideResourceCenter: boolean;
  lifecycleSPLandingPage: string;
  enableLMProductCards: boolean;
  hideCompositeFromAveragingDetails: boolean;
  showIntrinsicValue: boolean;
  showIncomeCompare: boolean;
  tisResourceEnabled: boolean;
  hideLifecycleManagerMenu: boolean;
  enableNewSPMarketplace: boolean;
  hideMarketplaceSPBuy: boolean;
  configurableDashboard?: ConfigurableDashboard;
};

export const URL_PATHS = {
  SECONDARIES: "secondaries",
  CREATIONHUB: "creationhub",
  DASHBOARD: "dashboard",
  CONFIGURABLE_DASHBOARD: "configurable-dashboard",
  ISSUER_DASHBOARD: "issuer-dashboard",
  RFQ_SEARCH: "rfq-search",
  QUOTES: "quotes",
  ORDERS: "orders",
  LP_BLOTTER: "lp-blotter",
  CONFIRMATIONS: "event-confirmations",
  ISSUER_CONFIRMATIONS: "issuer-confirmations",
  LIKELIHOOD_OF_CALL: "likelihood-of-call",
  LIFECYCLE: "lifecycle-manager",
  LIFECYCLE_EVENTS: "events",
  LIFECYCLE_PRODUCTS: "products",
  LIFECYCLE_ACCOUNTS: "accounts",
  LIFECYCLE_ACCOUNTS_REPS: "representative",
  STRUCTURED_PRODUCTS: "structured-products",
  PERF_REPORTS_COMP: "test/pdf",
  PORTFOLIO_REPORTS_TESTING: "test/portfolio",
  USER_PREFERENCES: "user-preferences",
  PERFORMANCE_REPORTS: "product-details-report-pdf",
  COMPACT: "compact",
  CARD: "card",
  GRAPHICAL: "graphical",
  PRODUCT_CARDS: "product-cards-pdf",
  ANNUITIES_LIFECYCLE: "annuities/lifecycle-manager",
};

export const VARIANT_MAP = {
  primary: {
    topNavBackground: LumaColor.PRIMARY_80_DAINTREE,
    topNavBorder: LumaColor.PRIMARY_80_DAINTREE,
    mobileBorder: LumaColor.NEUTRAL_70,
    accountIconMainColor: LumaColor.NEUTRAL_40,
    accountIconActiveColor: LumaColor.PRIMARY_50,
    textColor: LumaColor.NEUTRAL_40,
    activeTextColor: LumaColor.NEUTRAL_00_WHITE,
    activeHighlightColor: LumaColor.PRIMARY_50,
    activeFontWeight: "500",
  },
  secondary: {
    topNavBackground: LumaColor.NEUTRAL_00_WHITE,
    topNavBorder: LumaColor.NEUTRAL_40,
    mobileBorder: LumaColor.NEUTRAL_40,
    accountIconMainColor: LumaColor.NEUTRAL_80,
    accountIconActiveColor: LumaColor.NEUTRAL_50,
    textColor: LumaColor.NEUTRAL_80,
    activeTextColor: LumaColor.NEUTRAL_80,
    activeHighlightColor: LumaColor.NEUTRAL_50,
    activeFontWeight: "700",
  },
};
